.subscribe_container {
  padding-top: 71px;

  .main_body {
    max-width: 1140px;
    width: auto;
  }

  .site-width {
    max-width: 1140px;
    width: auto;
  }

  #site-header .site_logo img#logo,
  #site-header .site_logo svg#logo {
    @media screen and (width >= 1140px) {
      margin-left: 0;
    }
  }

  section.hero {
    padding-top: 0;
    clear: both;
    position: relative;

    .title-container {
      position: absolute;
      left: 18px;
      bottom: 8px;
      margin: 0;
      width: auto;
      font-size: 2.625em;
      line-height: 1.4em;
      font-weight: 200;

      span {
        display: inline-block;
        font-weight: 200;
        float: none;
        background-color: #000;
        color: #fff;
        padding: 5px 10px;
        margin: 5px 0;
      }
    }
  }

  .narrative {
    h2 {
      font-size: 2.2em;
    }

    h3 {
      font-size: 1.4em;
      font-weight: normal;
      margin-bottom: 5px;
    }

    p {
      font-size: 1.2em;
      line-height: 1.5em;
    }

    ul {
      font-size: 1.2em;
      line-height: 1.5em;
      margin-bottom: 10px;
    }

    li {
      margin-bottom: 10px;
    }
  }

  .grid-duet {
    display: inline-block;
    vertical-align: top;
    width: 48%;
    margin: 30px 0.5%;
  }

  section .non-active-cta {
    height: 50px;
    text-align: center;
    background-color: #4cb7ff;
    margin: 0;
    color: #fff;
    font-size: 24px;
    line-height: 50px;
  }
}
