$faq-overview__max-width: 1146px;
$faq-overview-tile__max-width: 572px;

$faq-content-pair__max-width: 545px;
$faq-content-pair-half__max-width: 272px;

$color-black-border: #333333;
$color-gray-border: #a7a7a7;
$color-links: #0099ff;
$color-gray-bullets: #666666;

body.faq_overview {
  .main_body {
    max-width: none;
    background-image: url(~static_content/faq/115888569.jpg);
    background-repeat: repeat;
  }
  a {
    color: $color-links;
  }
}

.faq-overview {
  width: 100%;

  h2 {
    font-size: 20px;
    line-height: 24px;
  }

  .faq-overview__headline {
    text-align: left;

    max-width: $faq-overview-tile__max-width;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 32px;
    line-height: 33px;
    color: $white;
    @media #{$medium-up} {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 40px;
      max-width: $faq-overview__max-width;
    }
  }

  .faq-overview__tile-area {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    max-width: $faq-overview__max-width;

    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }

  .faq-overview__tile {
    margin-top: 0;
    margin-bottom: 10px;
    max-width: $faq-overview-tile__max-width;
    position: relative;

    padding-left: 20px;
    padding-right: 20px;
    display: block;
    background: none;
    border: none;

    flex: 0 0 100%;
    width: 100%;
    text-align: center;

    @media #{$medium-up} {
      flex: 0 0 50%;
      width: 50%;
      text-align: left;
      margin-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      &:nth-child(2n) {
        page-break-after: always;
        break-after: always;
      }
    }
  }

  .faq-overview__tile-content {
    position: relative;
    width: 100%;
    max-width: 552px;
    padding: 20px;
    background: white;
    border: 1px solid $some-grey-border;

    display: flex;
    justify-content: left;
    align-items: center;

    @media #{$medium-up} {
      display: block;
      padding: 40px;
      justify-content: center;
    }
    a.overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: box-shadow 350;
      @media #{$medium-up} {
        position: static;
      }
    }

    header {
      h2 {
        white-space: nowrap;
        color: $grey-sub-head-title;
        @media #{$medium-up} {
          margin-right: 50px;
          margin-bottom: 20px;
        }
      }
    }
    hr.line-under-questions {
      display: none;
      color: $some-grey-border;
      @media #{$medium-up} {
        display: block;
        margin: 20px 0;
        border-bottom: none;
      }
    }
    hr.line-under-title {
      display: none;
    }
    img {
      width: 62px;
      margin-bottom: 20px;
      @media #{$medium-up} {
        float: right;
        margin: 0;
        width: auto;
        height: 31px;
      }
    }

    .faq-overview__questions-area {
      display: none;

      @media #{$medium-up} {
        display: block;
        height: 100px;
      }

      &,
      .question {
        color: $light-blue;
        font-size: 13px;
        line-height: 16px;
      }
      li {
        margin-bottom: 12px;
      }
    }

    .cta {
      display: none;
      margin-bottom: 0;
      font-size: 13px;
      color: $light-blue;
      line-height: 16px;
      .cat-default {
        color: $light-blue;
      }
      @media #{$medium-up} {
        display: block;
        text-align: right;
      }
    }
  }
}

.faq-attr-box__container {
  width: 100%;
  .faq-attr-box__content {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    color: $white;
    max-width: $faq-overview-tile__max-width;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    line-height: 16px;
    @media #{$medium-up} {
      max-width: $faq-overview__max-width;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 20px;
    }
  }
}

// In order to properly show 2 columns of bullet items,
// the max height for each faq content page must be set here,
// depending on the number of items
body.basics {
  .main_body {
    background-image: url(~static_content/faq/702613953.jpg);
  }
}

body.purchasing {
  .main_body {
    background-image: url(~static_content/faq/72967687.jpg);
  }
}

body.working-files {
  .main_body {
    background-image: url(~static_content/faq/165866154.jpg);
  }
}

body.downloading {
  .main_body {
    background-image: url(~static_content/faq/592029233.jpg);
  }
}

body.your-account {
  .main_body {
    background-image: url(~static_content/faq/593391206.jpg);
  }
}

body.troubleshooting {
  .main_body {
    background-image: url(~static_content/faq/624349502.jpg);
  }
}

body.faq_content {
  a,
  a:link,
  a:hover,
  a:visited {
    color: $color-links;
  }

  .main_body {
    max-width: none;
    background-repeat: repeat;
  }
  .site-width {
    align-content: center;
    background: none;

    @media #{$medium-up} {
      padding: 0 20px 0 20px;

      align-content: inherit;
      max-width: 1036px;
    }
  }

  .faq-content-title__container {
    display: none;
    width: 100%;
    max-width: 1146px;
    padding: 40px 0 10px 30px;
    margin: auto;

    h1 {
      font-size: 22px;
      line-height: 33px;
      color: $white;
      opacity: 1;
    }
    @media #{$medium-up} {
      display: block;
      padding: 40px 0 10px 0;
      h1 {
        font-weight: 300;
        font-size: 32px;
      }
    }
  }

  .faq-content-title-mobile__container {
    display: block;
    width: 100%;
    margin: auto;
    background: none;
    h1 {
      font-size: 22px;
      line-height: 33px;
      font-weight: 400;
      color: $white;
    }

    text-align: left;
    max-width: 562px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    @media #{$medium-up} {
      display: none;
    }
  }

  .faq-nav {
    margin: auto;
    font-size: 16px;
    line-height: 20px;

    padding-left: 20px;
    padding-right: 20px;

    .faq-nav__content {
      margin: auto;
      padding: 15px 20px 15px 20px;
      margin-left: 0;
      margin-right: 0;
      @media #{$medium-up} {
        margin: auto;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
  .faq-content {
    margin: auto;
    font-size: 16px;
    line-height: 20px;
    padding-left: 20px;
    padding-right: 20px;
    @media #{$medium-up} {
      padding-left: 30px;
      padding-right: 30px;
    }
    .faq-content__interior {
      margin: 0 0 0 0;
      padding: 0px 20px 20px 0;
      @media #{$medium-up} {
        font-size: 13px;
        line-height: 18px;
        padding: 35px 5px 15px 15px;
      }
    }
  }

  .faq-content-attr-box__spacer {
    background: white;

    margin-left: auto;
    margin-right: auto;
    color: $white;

    max-width: 542px;

    font-size: 16px;
    line-height: 20px;

    margin: 0 20px 0 20px;
    padding: 0 20px 0 20px;

    @media (min-width: 582px) {
      margin: auto;
    }

    @media #{$medium-up} {
      margin: auto;
      font-size: 13px;
      line-height: 16px;
      max-width: $faq-overview__max-width;
      padding-left: 40px;
      padding-right: 40px;
    }
    hr {
      margin: auto;
      @media #{$medium-up} {
        max-width: none;
      }
    }
    h3 {
      margin-top: 20px;
      font-size: 15px;
      padding-bottom: 40px;
    }
  }
  .faq-content-attr-box__container {
    @media (max-width: 582px) {
      padding-left: 20px;
    }
    width: 100%;
    .faq-content-attr-box__content {
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      color: $white;
      max-width: 542px;
      font-size: 13px;
      line-height: 16px;
      padding-left: 0px;
      padding-right: 10px;
      @media #{$medium-up} {
        max-width: $faq-overview__max-width;
      }
    }
  }
}

%faq-mobile-base {
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 582px;
  @media #{$medium-up} {
    width: 100%;
    max-width: 1146px;
    padding: 0;
  }
}

.faq-nav {
  @extend %faq-mobile-base;

  @media #{$medium-up} {
    width: 100%;
    max-width: 1146px;
    padding-left: auto;
    padding-right: auto;
  }

  .faq-nav__content {
    background: white;

    @media #{$medium-up} {
      float: left;
      margin-left: auto;
      width: 30%;
      max-width: 388px;

      margin-top: 43px;
      padding: 0 10% 15px 15px;
    }
  }

  .faq-nav__dropdown {
    @media #{$medium-up} {
      margin-bottom: 36px;
      display: none;
    }

    select {
      border-color: $color-gray-border;
      border-radius: 4px;
      color: $color-black-border;
      font-size: 16px;
      line-height: 20px;
      height: 44px;
      padding-left: 16px;
      background-color: transparent;

      background-position: right center;
      background-image: url(~static_content/faq/arrow-bullets-open-grey.svg);
      background-repeat: no-repeat;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }

  .faq-nav__list {
    display: none;
    font-weight: normal;
    @media #{$medium-up} {
      display: block;
      max-width: 225px;
    }
    h3 {
      display: none;
      font-weight: bold;
      margin: 8px 0;
    }
    hr {
      display: none;
      margin: 8px 0;
    }
    ul {
      margin: 25px 0;
    }
    li {
      font-size: rem(14);
      margin: 12px 0;
      padding-bottom: 5px;
      border-bottom: 1px solid $some-grey-border;
    }
    a:link {
      color: black;
      font-size: 16px;
      font-weight: normal;
    }
    a:hover {
      color: $color-links;
    }
    a:visited {
      color: black;
    }
    a.selected {
      color: $shale-grey;
      pointer-events: none;
      cursor: default;
    }
  }
}

.faq-content {
  @extend %faq-mobile-base;
  @media #{$medium-up} {
    max-width: 1146px;
    margin-top: 15px;
    font-size: 13px;
    a {
      font-size: 13px;
    }
  }

  h1 {
    margin: 1em 0;
  }

  h2,
  h3,
  h4,
  ul,
  ol {
    margin-bottom: 1.5em;
  }

  h3,
  h4 {
    font-weight: bold;
  }

  ul:not(.faq-content__index) {
    li {
      list-style-type: disc;
      margin: 4px 0 4px 1.2em;
    }
  }
  ol {
    li {
      list-style-type: decimal;
      margin: 4px 0 4px 1.2em;
      padding: 0 4px 4px 0;
    }
  }

  .faq-content__interior {
    @media #{$medium-up} {
      width: 70%;
      max-width: 776px;
      margin-left: auto;
      float: left;

      margin-top: 40px;
      padding: 40px 20px 0 20px;

      hr {
        margin: 0px;
        border: 1px solid $some-grey-border;
        @media #{$medium-up} {
          display: none;
        }
      }
    }

    .faq-content__index {
      padding-bottom: 20px;
      li:before {
        list-style-type: none;
        display: table-cell;
        content: '•';
        color: $color-gray-bullets;
      }
      li a {
        display: table-cell;
        padding-left: 5px;
      }
      padding-left: 0px;
      margin-left: 35px;

      @media #{$medium-up} {
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
        margin-left: 0;

        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;

        .faq-content__index-item {
          padding: 0 0 5px 0;
          margin: 0 0 0 0;
          break-inside: avoid-column;
        }
      }
      @media #{$medium-up} {
        border-bottom: 1px solid $some-grey-border;
      }
    }

    background: white;
    h1 {
      display: none;
    }
    h3 {
      font-size: rem(13);
      margin-bottom: 0px;
    }
  }

  .faq-content__headline {
    display: none;
    @media #{$medium-up} {
      display: block;
      margin: 0;
    }
  }
  .faq-content__qa-pair {
    margin: 20px 20px 40px 20px;
    padding-bottom: 20px;
    @media #{$medium-up} {
      padding-bottom: 0;
      margin: 20px 0px 0px 0;
    }
    p {
      margin-top: 1.5em;
    }
    border-bottom: 1px solid $some-grey-border;
    max-width: $faq-content-pair__max-width;
    .faq-content__index {
      li {
        margin-bottom: 5px;
      }
    }
    h3 {
      font-size: 16px;
      line-height: 20px;
      @media #{$medium-up} {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 10px;
      }
      color: $color-black-border;
    }
  }

  div.faq-content__qa-pair:first-child {
    @media #{$medium-up} {
      margin: 20px 0px 0px 0;
    }
  }

  div.faq-content__qa-pair:last-child {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    @media #{$medium-up} {
      margin-bottom: 20px;
    }
  }

  .faq-content__return-top {
    text-align: right;
    font-size: rem(14);
    line-height: 19px;
    margin: 1.5em 0;
  }
  .faq-content__index {
    list-style: none;
    li {
      margin-bottom: 8px;
    }
  }
  .faq-content__index + hr {
    margin: 40px 0 0 20px;
    color: $color-gray-border;
    @media #{$medium-up} {
      margin: 20px 0 0 0;
    }
  }

  .faq-content__index-item {
    margin-bottom: 8px;
  }
}

.faq-contact-info {
  text-align: center;
  max-width: 1146px;
  margin: auto;

  margin-bottom: 85px;

  h1,
  h2 {
    margin: 24px 0;
  }
  hr {
    margin: 30px 15px;
  }

  .faq-contact-info__block-area {
    display: -webkit-box;
    -webkit-box-direction: column;
    -webkit-box-flow: column wrap;
    display: -ms-flexbox;
    -ms-flexbox-direction: column;
    -ms-flexbox-flow: column wrap;
    display: flex;
    flex-direction: column;
    flex-flow: column wrap;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-flex-flow: column wrap;

    @media #{$medium-up} {
      justify-content: center;
      flex-direction: row;
      margin: 0;
    }
  }

  .faq-contact-info__block {
    display: block;
    line-height: 31px;
    text-align: center;
    font-size: rem(16);

    @media #{$medium-up} {
      font-size: rem(20);
      max-width: 33.3%;
      text-align: left;
    }

    img {
      display: block;
      margin: 16px auto;
      width: 62px;

      &.live-chat-icon {
        width: 76px;
      }

      @media #{$medium-up} {
        display: inline;
        vertical-align: top;
        width: 46px;
        min-height: 31px;
        margin: 0 20px 0 0;

        &.live-chat-icon {
          width: 46px;
        }
      }
    }

    & + .faq-contact-info__block {
      margin-top: 20px;
      @media #{$medium-up} {
        margin-top: 0;
        margin-left: 100px;
      }
    }
  }
}

.faq-contact-info--left-aligned {
  @extend .faq-contact-info;
  @media #{$medium-up} {
    width: 100%;
    max-width: 1146px;
    text-align: left;
    clear: both;
    > *,
    > hr,
    > .faq-contact-info__block-area {
      margin-left: 30%;
      padding-left: 15px;
    }
    hr {
      margin-left: calc(30% + 15px);
    }
    .faq-contact-info__block-area {
      justify-content: flex-start;
    }
  }
}
