.hero-window {
  max-width: 1280px;
  display: flex;

  @media only screen and (width >= 1302px) {
    margin: 9px auto;
  }

  @media #{$tablet} {
    display: block;
    margin-left: 0.6rem;
    margin-right: 0.6rem;
  }

  @media only screen and (width <= 400px) {
    margin: 0;
  }

  .hero-pane {
    flex-grow: 3;
    position: relative;
    display: block;

    .hero-image {
      background-size: cover;
      display: block;
      height: 214px;

      @media only screen and (width >= 400px) {
        min-height: 467px;
        height: 100%;
      }
    }

    figcaption {
      overflow: hidden;
      padding: 0 1rem;
      color: white;
      position: absolute;
      background: transparent linear-gradient(180deg, transparent 2%, #000 100%);
      bottom: 0;
      width: 100%;

      @media #{$medium-up} {
        padding: 0 0 0 4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: transparent
          linear-gradient(271deg, transparent 2%, #000 100%);
        top: 0;
        height: 100%;
        max-width: 60%;
      }

      @media only screen and (width >= 1024px) {
        max-width: 40%;
        overflow: visible;
      }

      .head-line {
        padding-bottom: 1rem;
        text-transform: uppercase;
        text-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
        font-size: 2.75rem;
        font-weight: 400;
        line-height: 2.5rem;
        letter-spacing: 0.03em;

        @media #{$medium-up} {
          font-weight: 100;
          line-height: 5rem;
          font-size: 4rem;
          padding-bottom: 2rem;
        }
      }

      .by-line {
        display: none;

        @media #{$medium-up} {
          display: inline-block;
        }

        font-size: 1.5rem;
        line-height: 2rem;
        padding-bottom: 1.5rem;
        text-shadow: 0 2px 4px rgb(0 0 0 / 50%);
      }

      .see-more {
        display: none;

        @media #{$medium-up} {
          display: inline-block;
        }

        border: 1px solid white;
        border-radius: 0.5rem;
        text-align: center;
        margin: 0 auto 0 0;
        padding: 1rem 1.5rem;
        font-size: 1.1rem;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }

  .grills {
    flex-grow: 1;
    margin-left: 0.6rem;

    @media #{$tablet} {
      margin: 0;
    }

    a:hover,
    a:focus {
      text-decoration: none;
    }

    .grill {
      display: flex;
      position: relative;
      padding: 1rem;

      @media #{$medium-up} {
        padding: 0;
        height: 230px;
      }

      .grill-image {
        width: 100%;
        height: 100px;
        margin: auto;

        @media only screen and (width >= 400px) {
          height: 200px;
          margin: 0;
        }

        @media #{$medium-up} {
          width: 100%;
          height: 100%;
        }

        display: inline-block;
        background-size: cover;
        background-repeat: no-repeat;
      }

      figcaption {
        display: inline-block;
        margin: auto 1rem;
        color: #4a4a4a;

        @media #{$medium-up} {
          margin: 0;
          color: white;
          position: absolute;
          bottom: 0;
          left: 0;
          background: linear-gradient(
            180deg,
            rgb(0 0 0 / 0%) 2%,
            rgb(0 0 0) 100%
          );
          text-shadow: 0 2px 4px rgb(0 0 0 / 50%);
        }

        width: 100%;
        padding: 0.5rem;
        font-size: 1.5rem;
        line-height: 1.75rem;

        .category {
          text-transform: uppercase;
          font-size: 1rem;
        }
      }
    }

    .grill:first-child {
      margin-bottom: 0.6rem;

      @media #{$mobile} {
        padding-bottom: 0;
      }
    }
  }
}
