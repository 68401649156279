.parallax {
  @media (min-width: 1200px) {
    background-size: cover;
  }
  @media #{$tablet} {
    height: auto;
  }
  display: block;
  background-repeat: no-repeat;
  height: 250px;
  text-align: center;

  &.p1 {
    background-color: white;
    padding-top: 40px;
    ul {
      margin-top: 20px;
    }
    li {
      display: inline-block;
      border: 1px solid #4cb7ff;
      border-radius: 15px;
      margin: 10px;
      background-color: #fff;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
      a {
        display: block;
        text-decoration: none;
        padding: 5px 20px;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }

  &.p2 {
    background-image: url(~static_content/home/row-bg-subscription.png);
    margin: 2em 0 0 0;
    @media #{$mobile} {
      background-color: black;
      background-image: none;
    }
    .veil {
      width: 100%;
      height: 100%;
      padding: 3rem 1rem;
      background-color: rgba(0, 0, 0, 0.5);
      h1 {
        color: #fff;
      }
      p {
        font-size: 20px;
        line-height: 34px;
        letter-spacing: 1.2px;
        font-weight: 300;
        margin: auto;
        color: white;
        max-width: 1000px;
        width: 65%;
        @media #{$tablet} {
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.3px;
          width: 80%;
        }
      }
      a {
        display: inline-block;
        margin-top: 30px;
        font-size: 14px;
        cursor: pointer;
        text-decoration: none;
        font-weight: normal;
        color: #fff;
        background-color: #4cb7ff;
        padding: 1rem;
        letter-spacing: 0.08em;
      }

      a:hover {
        background-color: #39adfc;
      }
    }
  }
}
