@import 'unisporkal-styles/stylesheets/unisporkal/getty/hospice/_config';
@import 'getty/legacy/events/_asset_modal';
@import 'getty/legacy/events/_asset_stream';
@import 'getty/legacy/events/_social_buttons';
@import 'getty/legacy/events/_carousel';
@import 'getty/legacy/events/_sidebar';

.events_container {
  font-family: $primary-font-family;
  overflow: hidden;

  .site-width {
    width: 100%;
    max-width: 100%;

    & > article,
    & > aside {
      position: fixed;
      overflow-y: scroll;
      top: 51px;
      bottom: 0;
    }

    & > article {
      width: 80%;
      background-color: $bravo_gray;
    }

    & > aside {
      width: 20%;
    }

    @media #{$tablet} {
      & > article {
        width: 100%;
      }

      & > aside {
        display: none;
      }
    }
  }

  .main_body {
    max-width: 100%;
    margin-top: 51px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }

  #site-header {
    margin-bottom: 0;
    color: $white;
    background-color: $alpha_gray;
    border-bottom-color: $bravo_gray;
    position: fixed;
    top: 0;
    z-index: 350;

    label[for='burger_toggle'] {
      color: $white;
    }

    .site_logo,
    .pop_up_header .site_logo {
      img {
        display: none !important;
      }
    }

    .site_logo::before {
      content: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/GettyImages-logo-black-122x25.png');
      vertical-align: middle;
      padding: 10px;
      margin-top: 3px;
      display: inline-block;
      float: left;
    }

    .nav-root > li,
    .nav-root > li::after,
    .nav-root > li > *,
    .nav-root #user-icon,
    .nav-root #user-display-name {
      color: $white;
    }
  }

  .top-hero {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 42.55%; // 2.35:1

    img {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .headline {
    padding: 3em;
    z-index: 0;
    position: relative;

    h1,
    h2,
    h3 {
      font-family: $primary-font-family;
      font-weight: lighter;
    }

    h1 {
      font-size: 46px;
    }

    h2 {
      font-size: 24px;
      margin-top: 0.25em;
    }

    h3 {
      color: $golf_gray;
      font-size: 18px;
      margin-bottom: 0.5em;
      margin-left: 0.15em;
    }

    .social-buttons {
      position: absolute;
      top: 1.5em;
      right: 2em;

      a::after {
        color: $golf_gray;
        opacity: 1;
      }

      @media #{$mobile} {
        top: 0.75em;
        right: 1em;

        li {
          margin: -0.5em 1.25em;
        }
      }
    }

    .twitter-follow-button {
      margin-top: 1em;
      display: block;
      opacity: 0.7;
    }
  }

  ul.boards {
    margin-left: 0.5em;
    font-size: 120%;
    color: $hotel_gray;

    li {
      display: inline;
      margin-right: 0.75em;

      &::after {
        margin-left: 0.75em;
        content: '|';
      }

      &:last-child::after {
        display: none;
      }

      &.selected a {
        cursor: default;
        color: #ddd;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }

    a {
      cursor: pointer;
    }
  }
}
