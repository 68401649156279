body.customer_support_container {
  .content_wrapper {
    div.how_to_reach_us {
      background-color: white;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 40px 40px 40px 40px;

      div.contact-section:after {
        content: '';
        display: table;
        clear: both;
      }

      div.contact-section {
        width: 100%;
        margin-right: 10px;
        margin-bottom: 5px;
      }

      h4 {
        display: block;
        margin-bottom: 5px;
        margin-right: 10px;
        float: left;
      }

      div.header {
        margin: 20px 0px 20px 0px;
        cursor: pointer;
      }

      div.local-office {
        div.header {
          margin: 20px 0px 0px 0px;
        }
      }

      div.icon {
        float: left;
        width: 45px;
      }

      div.label {
        float: left;
        line-height: 24px;
      }

      img.icon {
        display: block;
        margin: auto;
      }

      img.mail {
        position: relative;
        top: -1px;
        width: 25px;
      }

      img.phone {
        top: -4px;
        position: relative;
        width: 30px;
        left: -4px;
      }

      img.chat {
        top: -8px;
        position: relative;
        width: 30px;
      }

      div.expand {
        margin-right: 5px;
        text-align: right;
        cursor: pointer;
        line-height: 24px;

        a {
          font-size: 22px;
          text-decoration: none;
        }
      }

      .xp_content {
        margin-left: 45px;

        &.phone {
          p {
            margin-bottom: 15px;
            margin-top: 0px;
          }

          div.multiple_phone_number_text {
            p {
              margin-bottom: 20px;
              margin-top: -5px;
            }
          }

          p.customer_number {
            margin-bottom: 20px;
          }
        }
      }

      .mail_sent {
        background-color: $pale-yellow;
        text-align: center;
        padding: 33px 15px;
        margin-top: 30px;
        border-radius: 5px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media #{$large-up} {
          padding: 33px 33px;
        }
        .icon-checkmark {
          background: url(~static_content/customer_support/checkmark-thin.svg)
            no-repeat 50% 50%;
          background-size: 200%;
          mask: url(~static_content/customer_support/checkmark-thin.svg)
            no-repeat 50% 50%;
          mask-size: 200%;
          width: 30px;
          height: 20px;
          margin: auto auto 15px auto;
        }
        .label-position {
          font-size: 21px;
          line-height: 25px;
          font-weight: 400;
          color: #000000;
          margin-bottom: 11px;
          display: block;
        }
        p.support-message {
          font-size: 14px;
          line-height: 17px;
          font-weight: 400;
          color: #000000;
          margin-bottom: 18px;
          max-width: 285px;
        }
        a {
          font-size: 14px;
        }
      }
    }
  }
}
