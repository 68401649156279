.home_container {
  .home-slider {
    position: relative;
    margin: auto;
    margin-left: 0.52%;
    width: 100%;
    > img,
    > video,
    > div {
      position: absolute;
      opacity: 0;
      left: 0;
      transition: opacity 0.25s ease-in-out;
    }
    > img.active,
    > video.active,
    > div.active {
      opacity: 1;
      left: 0;
      position: relative;
    }
    > footer {
      margin: 0px;
      padding: 0px;
      position: absolute;
      bottom: 0;
      height: 3em;
      width: 100%;
      line-height: 3em;
      z-index: 100;
      box-sizing: border-box;
      background-color: rgba(255, 255, 255, 0.8);
      font-weight: bold;
      color: #000;
      a,
      span {
        padding-left: 20px;
        line-height: 1em;
        color: inherit;
      }
    }

    a.slidePrev,
    a.slideNext {
      display: block;
      position: absolute;
      top: 45%;
      width: 20px;
      height: 35px;
      z-index: 9;
      cursor: pointer;
      opacity: 0;
    }

    a.slidePrev {
      background: url('arrows.png') no-repeat scroll 0 0 transparent;
      left: 15px;
      text-indent: -100%;
    }

    a.slideNext {
      right: 15px;
      background: url('arrows.png') no-repeat scroll 0 -44px transparent;
      text-indent: 100%;
    }

    &:hover a.slidePrev,
    &:hover a.slideNext {
      opacity: 1;
    }
  }
}
