.on-off-toggle {
  @include on-off-switch(20px, 1px, $delta_primary);

  .onoffswitch {
    float: right;
  }

  .toggle-label {
    color: #333;
    float: left;
    font-size: 13px;
    line-height: 16px;
    margin: 2px 10px 0 0;
  }
}
