body.home-fancy.dark {
  #site-header {
    color: black;
    background-color: $alpha_gray;
    border-bottom-color: $bravo_gray;
  }

  .main_body {
    background-color: #262626;
  }

  label[for='burger_toggle'] {
    color: $white;
  }

  .nav-root > li,
  .nav-root > li::after,
  .nav-root > li > *,
  .nav-root #user-icon,
  .nav-root #user-display-name {
    color: $white;
  }

  .site_logo,
  .pop_up_header .site_logo {
    img {
      display: none !important;
    }
  }

  .full_header .site_logo #logo {
    display: none;
  }

  .full_header .site_logo #logo-negative {
    display: inline-block !important;
  }

  .carousel {
    h3 {
      color: white;
    }
  }

  .search-box-placeholder {
    background-color: #262626;

    #search-container-fancy {
      background-color: #262626;

      &.docked {
        background-color: #262626;
      }
    }

    .media-filter-label {
      background-color: #f4f4f4;
    }
  }
}
