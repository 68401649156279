@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';

.EpidemicSound {
  &.signed-in #music {
    display: none;
  }

  .site-width > div[style='clear: both;'] {
    background-color: $grey-20;
  }

  .site-width {
    background-color: $gi-white;
  }

  .static-hero {
    padding: 3rem 2rem;
    height: auto;
  }

  .static-hero > * {
    margin: 0;
  }

  .static-hero h1 {
    font-size: 32px;
    margin-bottom: 1.5rem;
  }

  .static-hero p {
    line-height: 1.5;
    font-weight: normal;
    font-size: 20px;
    max-width: 874px;
    margin: 0 auto;
  }

  #music {
    float: none;
    margin: 0;
    background-color: $grey-20;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 56px 20px;
    flex-wrap: wrap;
  }

  .featured-collection-tiles {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }

  #music .two-tile {
    float: none;
    max-width: 450px;
    padding: 0 10px;
    text-align: center;
    width: 100%;
    min-width: 270px;
    background-color: $gi-white;
    margin: 0 10px 10px;
  }

  #music .two-tile .tile-copy p:first-child {
    font-weight: bold;
  }

  a.button {
    display: inline-block;
    width: auto;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    background-color: $consumer-blue;
    color: $gi-white;

    &:hover,
    &:focus {
      background-color: $consumer-blue-hover;
    }
  }

  .featured-container {
    max-width: 940px;
    float: none;
    display: flex;
    flex-wrap: wrap;
  }

  .featured-container .featured-header h6 {
    text-align: center;
    max-width: 880px;
    font-size: 23px;
    margin: 0 auto;
    font-weight: lighter;
  }

  .featured-container .cms-grid-24 {
    padding: 20px;
  }

  .featured-container .cms-grid-8 {
    display: flex;
    flex-direction: column;
  }

  .featured-container .cms-grid-8 h5 {
    min-height: 5.45em; /* This is two line-heights of text + the bottom padding * the line-height. */
    padding-bottom: 0;
  }

  .featured-container .cms-grid-8 a.link {
    margin-top: auto;
  }

  .featured-content {
    min-width: 300px;
  }

  .featured-content h5 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .featured-content p {
    padding: 0 15px;
  }

  @media screen and (width <= 540px) {
    div.grid-24 .two-tile {
      border: 1px solid $grey-30;
    }

    .featured-container .cms-grid-8 h5 {
      min-height: 55px;
      padding-left: 75px;
      padding-right: 75px;
    }

    .featured-content p {
      padding-left: 75px;
    }
  }

  #faq {
    padding: 50px;
    background-color: $gi-white;
    max-width: 940px;
    margin: 0 auto;
  }

  #faq.grid-24 {
    float: none;
    width: auto;
  }

  #faq p {
    margin: 0 auto 10px;
    line-height: 1.5;
  }

  #faq p:first-child {
    text-align: center;
  }

  #faq p:nth-child(odd) {
    margin-bottom: 30px;
  }

  .faq-content-attr-box__spacer {
    padding-bottom: 30px;
    background-color: $gi-white;
  }

  .faq-content-attr-box__spacer h3 {
    font-size: 20px;
  }

  .full-width-container {
    line-height: 1.5;
    padding: 40px 10px;
    margin: 0;
    background-color: $grey-20;
    max-width: none !important;
  }

  .full-width-container > p {
    margin: 0 auto 10px;

    &:nth-child(1) {
      font-weight: bold;
    }

    &:nth-child(1) > span {
      font-weight: normal;
    }
  }
}
