#search-container {
  position: relative;
  font-family: $primary_font_family;

  ul,
  fieldset > ul {
    padding-left: 10px;
  }

  li {
    padding: 0.15rem;
  }

  form {
    position: absolute;
    margin-top: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @media (width <= 540px) {
      margin-top: 14%;
    }

    &.search-box {
      background: transparent;
      width: 40%;
      line-height: 0;
      min-width: 222px;

      @media (width <= 540px) {
        min-width: 212px;
      }
    }
  }

  fieldset[disabled] {
    display: none;
  }

  fieldset.search_box {
    background-color: $form_field_background_color;
  }

  fieldset > ul > li > label {
    font-size: 1.5em;
    margin-left: 1.2rem;
  }

  select {
    font-family: $primary_font_family;
    font-size: 1.3rem;
    margin: 0.5rem 0.5rem 0.5rem 2.2rem;
    width: 83%;
    height: 2.5em;
    padding: 0.3611em 0.4167em;
    border: 1px solid $border_color;
  }

  label::before {
    color: $white;
    background-color: $white;
    font-size: 0.8rem;
    padding: 0.3rem;
    margin-right: 0.6rem;
    border: 1px solid $alpha_gray;
    content: '1';
    font-family: GettyIcons;
    vertical-align: middle;
    line-height: 1;
  }

  input.phrase {
    border: none;
    height: auto;
    padding: 15px;
    width: auto !important;
    border-style: solid;
    border-color: #ccc;
    border-width: 1px 0 1px 1px;
    margin-right: 0;

    @media (width <= 540px) {
      padding: 8px;
      padding-right: 0;
      height: 39.7px;
      font-size: 120%;
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:checked + label::before {
    color: $white;
    background-color: $alpha_gray;
  }

  .search_box button {
    font-size: 220%;

    @media (width <= 540px) {
      font-size: 165%;
    }
  }

  .category-container {
    padding: 5px 0.5rem 0.5rem;
    background-color: $form_field_background_color;
    line-height: 1.2;
    border-style: solid;
    border-color: #ccc;
    border-width: 0 0 1px 1px;
    width: 87%;
    min-width: 222px;

    & > ul > li > label {
      font-weight: bold;
      font-size: 1.5em;
    }

    @media (width <= 540px) {
      width: 81%;
      min-width: 170px;
    }
  }

  .closeButton {
    color: $button_text;
    background-color: $alpha_gray;
    content: ')';
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    font-size: 1.8em;
    line-height: 0.7rem;
    width: 1em;
    height: 1em;
    padding: 0.4rem;
    margin: 0.5rem;
    position: absolute;
    top: 4.5rem;
    right: 13%;

    @media (width <= 540px) {
      top: 3.5rem;
      right: 19%;
    }
  }

  .search-media-types {
    color: $white;
    background-color: $form_label_color;
    float: left;
    padding: 1.2rem;
    font-size: 1.5em;
    line-height: 0;

    @media (width <= 540px) {
      padding: 1rem;
      font-size: 120%;
    }

    a {
      color: $white;
      cursor: pointer;
      text-decoration: none;
      white-space: nowrap;
    }
  }

  .search-box {
    background-color: $white;
  }

  .category-filter {
    background-color: $white;
    width: 100%;
  }
}

/* SEARCH BOX */

/* TODO temporary placeholder styling - To be moved to Unisporkal enviro */
input[type='text']::placeholder,
textarea::placeholder,
select::placeholder {
  color: $placeholder_text;
}
