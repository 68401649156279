.home_container.home {
  .content_wrapper {
    @extend %content_wrapper;
  }

  #site-header {
    @extend %site-header;
  }

  .suggestions-container ul {
    padding-left: 0;
  }
}
