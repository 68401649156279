.landing-tiles {
  position: relative;
}

.fancy-grid {
  max-width: 1280px;
  margin-left: 0.6rem;
  margin-right: 0.6rem;

  @media only screen and (width >= 1292px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media #{$tablet} {
    margin-left: 0;
    margin-right: 0;
  }

  .search-box-placeholder + & {
    padding-top: 1.5em;

    @media #{$mobile} {
      padding-top: 0.5em;
    }
  }

  &:last-of-type {
    margin-bottom: 0.4rem;
  }

  div.clipper {
    margin-left: -0.4rem;
    margin-right: -0.4rem;
    overflow: hidden;
    display: flex;
    flex-flow: row wrap;

    @media #{$tablet} {
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }

    @media #{$mobile} {
      margin-left: 0;
      margin-right: 0;
    }

    > article {
      margin: 0.2rem;
      flex-grow: 1;

      @media #{$tablet} {
        min-width: 260px;
      }

      @media #{$mobile} {
        min-width: 180px;
      }

      @media only screen and (width >= 1024px) {
        min-width: 0;
        margin: 0.4rem;
      }
    }

    a {
      box-sizing: border-box;
      flex: 1;
      background-size: cover;
      background-position: center center;
      display: none;

      &.active {
        display: block;
      }
    }
  }

  figure {
    position: relative;
    height: 14rem;
    transition-property: height;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;

    @media #{$tablet} {
      height: 24rem;
    }

    @media only screen and (width >= 1024px) {
      height: 20rem;
    }

    img {
      display: none;
      max-width: 100%;
    }

    figcaption {
      position: absolute;
      bottom: 0;
      width: 100%;

      @extend %scrim;

      color: white;
      box-sizing: border-box;
      padding: 1rem;
      font-size: 16px;
      line-height: 1.5rem;
      letter-spacing: 0.01em;
    }

    .category-tag {
      font-size: 11px;
      letter-spacing: 0.02em;
      color: #fff;
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  &.subtitle {
    h3 {
      margin-left: 0;
      margin-top: 2rem;
      margin-bottom: 0.3rem;

      @media #{$tablet} {
        margin-bottom: 0.7rem;
        margin-top: 1rem;
        text-align: center;
      }
    }
  }
}
