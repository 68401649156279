div.tax-policy {
  padding: 30px 0;

  ol {
    list-style-type: decimal;
    margin-left: 1.4em;
    margin-bottom: 1.5em;
  }

  h1 {
    text-align: center;
    border-bottom: 1px solid;
    padding-bottom: 30px;
  }

  p.first {
    padding-top: 30px;
  }

  p.last {
    font-style: italic;
  }
}
