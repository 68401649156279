@media screen and (max-width: $max_mobile_width) {
  body.customer_support_container .content_wrapper {
    div.how_to_reach_us {
      padding: 20px;
    }
  }
}

@media (width <= 768px) {
  body.customer_support_container .content_wrapper {
    .customer_support {
      div.how_to_reach_us {
        padding: 20px;
      }
    }
  }
}

@media (width <= 1430px) {
  body.customer_support_container .content_wrapper div.site-width {
    width: 100%;
  }
}
