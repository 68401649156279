@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_z_index';

.headerSearchBar {
  // The headerSearchBar class is used to style the React Search Bar and override any existing styles.
  button,
  input,
  optgroup,
  select,
  textarea,
  label,
  form {
    font-family: Lato, sans-serif !important;
    font-size: 16px !important;
    text-transform: none;
    font-weight: 400;
    line-height: 1.15;
    padding-bottom: unset;
    padding-top: unset;
    letter-spacing: normal;
  }

  button {
    &:hover,
    &:focus {
      background-color: transparent;
      color: black;
    }
  }

  label {
    &:hover,
    &:focus {
      color: $purple;
    }
  }

  svg span {
    padding-right: 20px;
  }

  button[aria-label='search'] {
    width: unset;
    padding: unset;
  }

  header button {
    font-size: 12px !important;
  }

  header {
    div:first-child {
      font-family: Lato, sans-serif !important;
      font-size: 12px !important;
    }
  }

  li span {
    font-family: Lato, sans-serif !important;
    font-size: 16px !important;
  }

  background-color: $drk-light-grey;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 59px;
  z-index: 3;

  @media #{$medium-up} {
    padding: 1em 2em;
    height: 93px;
  }
}

.front_door_container {
  @include sticky-superstrip;
  @include superstrip-offset(margin-top);

  .content_wrapper {
    @extend %content_wrapper;
  }

  #site-header {
    @extend %site-header;

    #logo {
      margin-left: 20px;
    }
  }

  .mt70 {
    @include header-offset(margin-top, 19px);
  }

  .hero-1180 {
    @include header-offset(padding-top, 19px);
  }

  .main_body,
  .content_wrapper,
  #site-header {
    max-width: inherit;
    min-width: unset;
    padding-top: 0;
  }

  &.footage {
    .content_wrapper {
      padding-top: 51px;
    }
  }

  .site-width {
    width: auto;
    max-width: none;

    .inner_content_40_perc {
      margin: auto;
      width: 40%;

      @include header-offset(margin-top, 19px);
    }
  }

  .promo-banner {
    overflow: auto;
    padding-top: 9rem;
    padding-bottom: 9rem;
    color: black;
    background-color: white;

    &.dark {
      background-color: black;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: white;
        font-weight: 200;
      }
    }

    .billboard-container {
      -ms-flex-align: center; // IE11 needs this for the auto-margin vertical centering
      display: flex;
    }

    .image-container {
      width: 30%;
      margin: auto 2% auto 18%;

      .no-flexbox & {
        // IE9 and IE10 don't support flexbox, so they get the mobile styling
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
      }
    }

    .copy-container {
      width: 28%;
      margin: auto 18% auto 2%;

      .no-flexbox & {
        // IE9 and IE10 don't support flexbox, so they get the mobile styling
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
      }
    }

    &:nth-of-type(odd) {
      .image-container {
        order: 2;
        margin-left: 2%;
        margin-right: 18%;
        text-align: right;

        .no-flexbox & {
          // IE9 and IE10 don't support flexbox, so they get the mobile styling
          margin-right: auto;
          margin-left: auto;
          text-align: center;
        }
      }

      .copy-container {
        order: 1;
        margin-left: 18%;
        margin-right: 2%;

        .no-flexbox & {
          // IE9 and IE10 don't support flexbox, so they get the mobile styling
          margin-right: auto;
          margin-left: auto;
          text-align: center;
        }
      }
    }

    &.dark:nth-of-type(even) {
      background-color: $bravo_gray;
    }

    &.dark:nth-of-type(odd) {
      background-color: $charlie_gray;
    }
  }

  .cms-bookend {
    &.dark {
      background-color: #000;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        color: white;
      }
    }

    text-align: center;
    overflow: auto;
    padding-top: 175px;
    padding-bottom: 125px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
      font-weight: 200;
    }

    .sub-title {
      font-size: 2.333em;
    }

    .scroll {
      font-size: 1.333em;
      color: $delta_color !important;
    }

    a.button {
      font-size: 1.333em;
      max-width: 17%;
      background-color: $button_color;
      display: block;
      margin-top: 60px;
      margin-bottom: 60px;
      font-weight: normal;

      &.alt {
        border: solid white 1px;
        background-color: transparent;

        &:hover,
        &:focus {
          background-color: $bravo_gray;
        }
      }
    }
  }

  .tour-title {
    font-size: 3.5em;
    margin-bottom: 30px;
  }

  .tour-description {
    font-size: 2em;
  }
}

@media (width <= 960px) {
  .front_door_container {
    .promo-banner {
      .billboard-container {
        height: 100%;
        padding-left: 5%;
        padding-right: 5%;
        margin: 0 auto;
        flex-direction: column;
      }

      .copy-container,
      &:nth-of-type(odd) .copy-container {
        width: 90%;
        font-size: 80%;
        padding-bottom: 60px;
        order: 1;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
      }

      .image-container,
      &:nth-of-type(odd) .image-container {
        width: 100%;
        order: 2;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
      }
    }

    .cms-bookend {
      font-size: 80%;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        max-width: 90%;
      }

      a.button {
        max-width: 50%;
      }
    }
  }
}

@media (width <= 320px) {
  .front_door_container .cms-bookend {
    max-height: 450px;
  }
}
