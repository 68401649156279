body.customer_support_container {
  .content_wrapper {
    .customer_support {
      .email_container {
        select {
          width: 100%;
          margin: 5px 0 15px;
        }

        p.privacy-statement {
          text-align: center;
        }

        p.thank-you-message {
          color: #0c0;
          margin: 0;
          padding-top: 5px;
        }

        .g-recaptcha-protected-message {
          font-size: inherit;
          line-height: inherit;
          text-align: center;
          margin-bottom: 15px;
        }

        form {
          margin: 0;
        }

        select[disabled='disabled'] {
          border: 1px solid #cecaca;
          padding: 0.3611em 0.4167em;
        }

        ng-message,
        .error-message {
          color: #f00;
          padding-top: 10px;
          display: block;
        }

        li.big-space {
          margin-bottom: 30px;
        }

        li.medium-space {
          margin-bottom: 20px;
        }

        li.small-space {
          margin-bottom: 10px;
        }

        text-align: left;

        button {
          margin-top: 10px;
        }

        button,
        a.button,
        input[type='text'],
        textarea {
          font-size: 14px;
        }

        textarea {
          height: 80px;
        }

        fieldset ol {
          margin-bottom: 0;
        }

        input[type='file'] {
          display: none;
        }

        div.select-container {
          margin-left: 25px;
          margin-bottom: 10px;
        }

        span.attachment {
          margin: 0;
          padding: 0;

          label {
            font-size: 14px;
            line-height: 16px;
            color: #4cb7ff;
            cursor: pointer;
            font-weight: normal;

            &:hover,
            :active,
            &:focus,
            :active {
              text-decoration: underline;
            }
          }

          div.attachment-container {
            line-height: 30px;
          }

          div.icon {
            margin-left: -10px;
            margin-right: -15px;

            & img {
              margin: 0;
              width: 35px;
            }
          }

          a {
            margin-left: 10px;
          }

          p {
            margin: 0;
          }
        }

        span.clarification {
          font-size: 11px;
          margin-left: 8px;
        }

        p.error-message {
          margin: 0 0 10px;
        }

        .assisting-type-container {
          display: inline-block;
          width: 100%;

          .radio-button {
            display: inline-block;
          }

          input[type='radio']:checked {
            border: 2px solid #4cb7ff;
            outline: none;
            background-color: white;
          }

          label {
            cursor: pointer;
          }
        }
      }
    }
  }

  .email_container_visible {
    visibility: visible;
  }

  .email_container_invisible {
    visibility: hidden;
  }

  .spinner_overlay {
    top: -250px;
  }

  .spinner_visible,
  .thank_you_visible {
    visibility: visible;
  }

  .spinner_visible,
  .thank_you_invisible {
    visibility: hidden;
  }
}
