@import 'getty/legacy/customer_support/_how_to_reach_us';
@import 'getty/legacy/customer_support/_email_section';

.show-local-office-info-modal {
  @include header-offset(top);
}

body.customer_support_container {
  background-color: gray;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;

  label {
    color: #1a1a1a;
  }

  .content_wrapper {
    padding-top: 50px;
    p {
      line-height: 18px;
      font-weight: normal;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    a {
      line-height: 18px;
      cursor: pointer;
    }

    hr {
      border: none;
      height: 1px;
      color: #a8a8a8; /* old IE */
      background-color: #a8a8a8; /* Modern Browsers */
    }
    a {
      color: #4cb7ff;
    }
    .main_body {
      max-width: none;
      background-image: url('~static_content/customer_support/background.jpg');
    }
    h2 {
      color: white;
      font-size: 28px;
      line-height: 33px;
      font-weight: normal;
    }
    div.site-width {
      width: 70%;
      max-width: none;
      margin: 0 auto;
      display: table;

      select {
        -moz-appearance: none;
        background-image: url(data:image/gif;base64,R0lGODlhFwAHAOMIAFNTU1xcXGZmZoODg5mZmbm5udvb2/Ly8v///////////////////////////////yH5BAEKAA8ALAAAAAAXAAcAAAQq8AyDajXj2M0HCNQVAANnIgXwUcYIFKdZjAHhwrHZqmse7yBfziAICS0RADs=);
        background-repeat: no-repeat;
        background-position: right;
        padding-right: 28px !important;
      }
    }

    div.content_creators {
      background-color: white;
      margin-bottom: 20px;
      padding: 40px 40px 40px 40px;

      p {
        margin-bottom: 0px;
      }
    }
    div.left {
      margin: 20px 12px 20px 20px;
    }

    div.right {
      margin: 20px 20px 20px 12px;
    }

    div.pageheader {
      display: block;
      margin: 20px 20px 0px 20px;
    }

    div.top_faqs {
      background-color: white;

      div.answer {
        margin-top: 1.5em;
      }
    }

    select {
      font-size: 13px;
      width: auto;
      display: block;
      float: left;
      margin-top: 0;
    }

    div.view-more-link {
      display: inline-block;
      width: 100%;
      text-align: right;
      padding-top: 10px;
    }

    div.view-all-link {
      text-align: right;
    }

    a.anchor {
      padding-top: 80px;
      margin-top: -80px;
      display: block;

      @media (max-width: 450px) {
        padding-top: 130px;
        margin-top: -130px;
      }
    }
  }
  #footer {
    margin: 0;
  }
}
@import 'shared/legacy/customer_support/_mobile';
@import 'getty/legacy/customer_support/_mobile';
@import 'getty/legacy/customer_support/member_update';
