div.modern-slavery {
  @media only screen and (width >= 1024px) {
    width: 1024px;
  }

  margin-left: auto;
  margin-right: auto;
  padding: 115px 40px 50px;

  span,
  div {
    font-size: 14px;
    line-height: 1.5;
  }

  ul {
    list-style-type: disc;
    margin-left: 1.4rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 30px;
    font-weight: 400;
    padding-bottom: 10px;
  }

  h3 {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 0;
  }

  div {
    font-weight: 500;
  }
}
