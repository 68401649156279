body.member_update_container {
  background-color: white;

  .content_wrapper {
    padding-top: 0;

    select {
      max-width: 210px;
    }

    .customer_support {
      h4 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        margin: 24px 0 32px;
        color: #333;
      }

      h5 {
        font-weight: 500;
        color: #333;
        font-size: 14px;
        margin-top: 12px;
      }

      label {
        color: #666;
        font-size: 14px;
      }

      .email_container {
        li.medium-space {
          margin-bottom: 0;
        }

        p.privacy-statement {
          text-align: left;
          font-size: 12px;
          margin-top: 10px;

          a {
            color: #09f;
          }
        }
      }

      .update-header {
        text-align: center;

        p {
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 20px;
          margin-top: 0;
        }
      }

      div.how_to_reach_us {
        background-color: white;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;

        .xp_content {
          margin-left: 0;
        }
      }
    }

    p {
      margin-bottom: 0;
      margin-top: 0;
    }

    .main_body {
      background-image: none;
    }

    div.site-width {
      width: 90%;
    }
  }

  .flexContainer {
    display: flex;
  }

  .flexSpaceAround {
    justify-content: space-around;
  }

  .flexItem {
    flex: 1;
  }

  .flexColumn {
    flex-direction: column;
  }

  .subContainer {
    width: 100%;
    margin: 0 auto;
  }

  .col1 {
    width: 220px;
    background: white;
    padding: 0;
    order: 1;
  }

  .col2 {
    width: 320px;
    background: white;
    margin-left: 50px;
    order: 2;
  }
}
