.divTable {
  display: table;
  width: 80%;
  border: 1px solid #000;
  margin: 0 auto;
  margin-bottom: 1.5em;
}

.divTableRow {
  display: table-row;
}

.divTableCell,
.divTableHead {
  border: 1px solid #999;
  display: table-cell;
  padding: 3px 10px;
  width: 50%;
}

.divTableHeading {
  background-color: #eee;
  display: table-header-group;
  font-weight: bold;
}

.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}

.divTableBody {
  display: table-row-group;
}

@media (width <= 1024px) {
  .divTable {
    width: 95%;
  }
}

div.eula ul {
  list-style-type: disc;
  margin-left: 1.4em;
  margin-bottom: 1.5em;
}

div.eula ul ul {
  list-style-type: circle;
  margin-left: 2.8em;
}

div.eula ol {
  list-style-type: decimal;
  margin-left: 1.4em;
  margin-bottom: 1.5em;
}

div.eula ol ol {
  list-style-type: lower-alpha;
  margin-left: 2.8em;
}
