.events_container .asset-stream {
  margin: 0 3em 3em 3em;

  .hero-title {
    color: white;
    background-color: black;
    font-weight: lighter;
    margin: 0 -3em;
    padding: 3em;

    h1,
    h2 {
      font-weight: lighter;
    }
    h1 {
      font-family: $primary-font-family;
      font-size: 200%;
    }
    h2 {
      font-family: $primary-font-family;
      font-size: 125%;
      margin-bottom: 0.5em;
      color: $golf_gray;
      margin-left: 0.05em;
    }
  }

  .hero {
    width: calc(100% + 6em); // Overflow margins for full bleed
    position: relative;
    padding-bottom: 56.25%; // 16:9
    margin-left: -3em;
    overflow: hidden;

    img.spaceball {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    img.asset {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    .title {
      font-size: 400%;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .asset-set {
    padding-bottom: 8em;
  }

  .asset-grid {
    margin-top: 3em;
    overflow: hidden;

    &.collapsed {
      max-height: 1000px;
      -webkit-mask-image: -webkit-gradient(
        linear,
        left 95%,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
      );
    }

    .asset-container {
      text-align: justify;
    }

    .board-item {
      position: relative;
      display: inline-block;
      margin: 7px;

      @media #{$mobile} {
        width: 100%;
        text-align: center;
      }

      &:hover .metadata {
        display: block;
      }
    }

    .metadata {
      display: none;

      font-size: 120%;
      position: absolute;
      bottom: 1em;
      width: 100%;
      opacity: 0.8;
      color: $india_gray;
      padding: 0.25em;
      background-color: rgba(0, 0, 0, 0.8);
      overflow: hidden;
      font-weight: lighter;
      text-align: left;
      .description {
        padding-left: 1em;
        color: white;
      }
    }

    img.board-asset {
      height: 300px;
      max-width: none;
      padding: 0;

      @media #{$mobile} {
        width: auto;
        height: auto;
      }
    }
  }

  .show-all {
    display: none;
    width: 100%;
    text-align: center;
    margin-top: 3em;

    button {
      width: 300px;
      font-family: $primary-font-family;
      color: $white;
      background-color: $alpha_gray;
      border: 1px solid $echo_gray;
      &:hover {
        border-color: $white;
      }

      @media #{$mobile} {
        width: 100%;
      }
    }
  }

  .asset-grid.collapsed + .show-all {
    display: inherit;
  }

  .title {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0.25em 0.5em;
    color: white;
    background-color: black;
    opacity: 0.85;
  }

  .show-more-galleries {
    font-size: 400%;
    font-weight: lighter;
    width: 100%;
    text-align: center;
    margin-top: 1em;

    a {
      color: $india_gray;
      text-transform: uppercase;
      &:hover {
        color: $white;
        text-decoration: none;
      }

      &:after {
        display: inline-block;
        margin-bottom: 7px;
      }
      @extend %arrow-right-icon-after;
    }
  }
}
