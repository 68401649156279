.events_container .carousel {
  width: 100%;
  position: fixed;
  z-index: 2000;
  height: 20%;
  bottom: 5%;
  left: auto;
  right: auto;
  text-align: center;

  .image {
    display: inline-block;
    height: 100%;

    img {
      height: 100%;
      border: 1px solid rgb(0 0 0 / 30%);
    }
  }
}
