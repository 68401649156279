.feedback_container {
  .content_wrapper {
    @extend %content_wrapper;
  }

  #site-header {
    @extend %site-header;
  }

  .new_feedback {
    #feedback_comments {
      height: rem(100);
    }

    h4 {
      margin-bottom: 0.75em;
    }

    .field_with_errors input {
      border-style: solid;
      border-color: red;
    }
  }

  .site_feedback,
  .feedback_thank_you {
    @extend %split-page-left;
  }

  .site_help {
    @extend %split-page-right;
  }
}
