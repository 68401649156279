.landing_container {
  .vr-headline-hero {
    padding-top: 5rem;
    display: block;
    position: absolute;
    width: 100%;
    z-index: 1;
    pointer-events: none;

    @media #{$mobile} {
      h1 {
        font-size: 362%;
      }

      p {
        font-size: 175%;
      }
    }
  }

  .vr-video,
  .vr-image {
    position: relative;
  }

  .vr-fallback {
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 1280px;
    height: 275px;
  }

  #panorama-video {
    margin: 0 auto;
    width: 100%;
    height: 340px;
  }

  iframe {
    display: block;
    margin: 0 auto;
    height: 300px;
    width: 100%;
    max-width: 1280px;
  }

  #panorama-image {
    margin: 0 auto;
    width: 100%;
    height: 250px;
  }

  .pnlm-controls {
    background-color: rgb(255 255 255 / 70%);
  }
}
