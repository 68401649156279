ul.social-buttons {
  color: $india_gray;

  @media not #{$mobile} {
    margin: 0 auto;
    padding: 0;
  }

  li {
    width: 30px !important;
    height: 30px !important;
    display: inline-block;
    text-decoration: none;
    margin: 2em 1em;
    z-index: 100;

    a,
    a:link {
      text-decoration: none;
    }

    a::after {
      font-size: 36px;
      font-family: GettyIcons;
      color: #fff;
      margin-left: -5px;
      border-radius: 21px;
      background-color: black;
      opacity: 0.8;
    }

    .twitter-share-button {
      &::after {
        color: #fff;
        content: $twitter-circle-icon !important;
      }

      &:hover::after,
      &:focus::after {
        color: #00aced;
      }
    }

    .facebook-share-button {
      &::after {
        color: #fff;
        content: $facebook-circle-icon !important;
      }

      &:hover::after,
      &:focus::after {
        color: #3b5998;
      }
    }

    .embed-share-button {
      &::after {
        color: #fff;
        content: $embed-circle-icon !important;
        cursor: pointer;
      }

      &:hover::after,
      &:focus::after {
        color: $bravo_color;
      }
    }
  }
}
