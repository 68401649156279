body.home-fancy {
  @include non-sticky-superstrip;

  background-color: white;

  .main_body {
    max-width: none;
    padding: 0;
    background-color: #f7f7f7;
  }

  .site-width {
    width: 100%;
    max-width: 100%;
  }

  #site-header {
    max-width: 1280px;
    margin-bottom: 0;
    border: none;
    position: static;
    top: 0;
    z-index: 350;

    @media (width <= 1280px) {
      .site_logo img {
        margin-left: 20px;
      }
    }

    nav.categories {
      position: relative;
      z-index: 5;
    }

    nav.actions {
      z-index: 4;
    }

    label[for='burger_toggle'] {
      z-index: 5;
    }

    #live-chat .lpLnk {
      height: auto;
      border-top: none;
    }

    .main_body {
      max-width: 100%;
      margin-top: 51px;
    }
  }

  .search {
    height: 100px;
  }

  .two-column {
    > section {
      min-width: 670px;
      width: 50%;
      display: inline-block;
      vertical-align: top;
    }

    > section:first-child {
      text-align: right;
    }

    > section:last-child {
      text-align: left;
    }
  }

  .carousel {
    padding-top: 2rem;
    max-width: 1280px;
    margin: 0 auto;

    @media #{$tablet} {
      padding-top: 0;
      text-align: center;
    }

    h3 {
      // color: white;
      font-size: 2.333em;
      margin-bottom: 0.7rem;
      margin-top: 2rem;
      display: inline-block;
    }

    .image_pager .alt_nav {
      display: none;
    }

    .image_pager ul:first-child::before,
    .image_pager ul:last-child::after {
      display: none;
    }

    .carousel-header-link {
      border-left: 2px solid #b3b3b3;
      font-size: 1.5em;
      font-weight: 200;
      margin-left: 0.75em;
      padding-left: 0.75em;
      display: inline-block;
    }
  }

  #footer {
    margin: 0;
  }

  .content_wrapper {
    padding-top: 0;
  }
}

#non-sticky-superstrip {
  display: block;
}

#sticky-superstrip {
  display: none;
}
