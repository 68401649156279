.events_container {
  .asset-modal {
    position: fixed;
    overflow-y: auto;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparentize($bravo_gray, 0.05);
    z-index: 1000;

    @media not #{$mobile} {
      .social-buttons,
      .metadata-container,
      .close,
      .nav-arrows {
        opacity: 0;
        transition: opacity 250ms;
      }

      &:hover {
        .social-buttons,
        .metadata-container,
        .close,
        .nav-arrows {
          opacity: 0.8;
        }
      }
    }

    &.loading {
      .content,
      .social-buttons,
      .metadata-container {
        opacity: 0 !important;
      }
    }

    .social-buttons {
      position: fixed;
      top: 0;
      left: 1.5em;
      z-index: 1001;
      color: $india_gray;
      &:hover {
        opacity: 0.8;
      }
      transition: opacity 0.1s;

      @media #{$mobile} {
        position: static;
        width: 100%;
      }
    }

    .preload {
      display: none;
    }

    article {
      float: left;
      text-align: center;
      position: absolute;
      overflow: hidden;
      width: 100%;

      .content {
        opacity: 1;
        transition: opacity 0.1s;
        width: 100%;

        .comp {
          width: 100%;
          img.spaceball {
            position: fixed;
            width: 100%;
            height: 100%;
          }
          img.asset {
            max-height: 1280px;
          }
        }
      }
    }

    .close {
      @extend %close-icon;
      position: fixed;
      right: 32px;
      top: 32px;
      width: 26px;
      height: 26px;
      padding-left: 1px;
      z-index: 1000;

      line-height: 1;
      font-size: 150%;

      color: $india_gray;
      background-color: $bravo_gray;
      &:hover {
        opacity: 0.8;
      }

      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }

    .metadata-container {
      position: fixed;
      top: 8rem;
      left: 2rem;
      width: 20rem;

      text-align: left;
      padding: 1em;
      color: white;
      background-color: black;
      cursor: pointer;
      font-weight: lighter;

      @media #{$mobile} {
        position: static;
        width: 100%;
      }

      .metadata {
        .license-this-image {
          margin-top: 0.5em;
        }
      }
    }

    .nav-arrows {
      .prev-asset,
      .next-asset {
        position: fixed;
        top: calc(50% - 0.5em);
        color: $india_gray;
        font-size: 400%;
        cursor: pointer;
        background-color: $bravo_gray;
      }

      .prev-asset {
        @extend %chevron-left-icon;
        left: -0.25em;
      }

      .next-asset {
        @extend %chevron-right-icon;
        right: -0.25em;
      }
    }

    @media #{$tablet} {
      article {
        width: 100%;
        float: none;
        text-align: center;
        position: relative;
        transform: none;
      }

      aside.comments {
        float: none;
        width: 100%;
      }

      .nav-arrows {
        .prev-asset,
        .next-asset {
          top: 4em;
          transform: none;
        }
      }
    }
  }
}
