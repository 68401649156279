.events_container .sidebar {
  position: fixed;
  top: 51px;
  bottom: 0;
  right: 0;
  padding: 1.5em;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $alpha_gray;
  border-left: 2px solid $bravo_gray;

  .asset-set {
    position: relative;
    width: 100%;
    margin-bottom: 1.5em;
    overflow: hidden;
    opacity: 0.8;

    &:hover,
    &:focus {
      opacity: 1;
    }

    .image {
      width: 65px;
      height: 65px;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        max-width: initial;
        max-height: initial;
        margin: auto;

        &.horizontal {
          left: -50%;
          right: -50%;
          height: 100%;
        }

        &.vertical {
          top: -50%;
          bottom: -50%;
          width: 100%;
        }
      }
    }

    .title {
      position: absolute;
      color: white;
      font-weight: lighter;
      font-size: 110%;
      top: 50%;
      text-align: left;
      transform: translateY(-50%);
      margin-left: 75px;
    }
  }

  a.current .asset-set {
    background-color: $charlie_gray;
    opacity: 1;
  }
}
