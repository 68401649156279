@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_fonts';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/base/_buttons';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/base/_typography';

.unsupported_container {
  background-color: $grey-20;

  .main_body {
    width: 100%;
    min-width: 320px;
    margin-right: 0;
  }

  h1,
  h2,
  a {
    font-family: $main-font-family;
  }

  .unsupported-header {
    background-color: $gi-white;

    .site-logo,
    img {
      max-width: 175px;
    }
  }

  .browser-info {
    background-color: $grey-20;
    color: $gi-black;

    .headers {
      max-width: 853px;

      h1 {
        font-size: 3rem;
      }

      h2 {
        font-size: 1.5rem;
      }
    }

    .browsers > div a {
      color: $gi-black;
      font-size: 1.25rem;

      & :hover,
      & :focus {
        color: $primary-color-highlight;
      }
    }

    .btn.btn--hollow {
      display: inline-block;
      text-transform: uppercase;
      background-color: transparent;
      padding: 16px 24px;
      font-size: 1.25rem;

      &:visited:not(:hover),
      &:visited:not(:focus) {
        color: $gi-black;
      }

      &:hover:not([disabled]),
      &:focus:not([disabled]) {
        background-color: $gi-black;
      }
    }
  }
}
