%content_wrapper {
  float: none;
  clear: both;
  max-width: 1036px;
  margin: 0 auto;
  padding-top: 70px;
}

%site-header {
  margin-bottom: 0;
  max-width: 1036px;
  background-color: $white;
  z-index: 350;

  &::before,
  &::after {
    content: '';
    background-color: $white;
    border-bottom: 1px solid #ccc;
    position: absolute;
    bottom: -1px;
    height: 100% !important;
  }

  &::before {
    right: 100%;
  }

  &::after {
    left: 100%;
  }
}
