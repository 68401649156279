.hero-carousel {
  width: 100%;
  overflow: hidden;
  text-align: center;
  position: relative;
  @media (min-width: 650px) {
    height: 340px;
  }
  .slide-control {
    position: absolute;
    top: 44%;
    width: 20px;
    height: 35px;
    cursor: pointer;
    z-index: 3;
    padding: 0.5rem;
    &.next {
      right: 1rem;
      background: url('/gi-resources/css/arrows.png') no-repeat scroll 0 -44px transparent;
      text-indent: 100%;
    }
    &.prev {
      left: 1rem;
      background: url('/gi-resources/css/arrows.png') no-repeat scroll 0 0
        transparent;
      text-indent: -100%;
    }
  }

  .hero-slides {
    white-space: nowrap;
    height: 100%;
    &.scroll-area {
      overflow: none;
      overflow-y: scroll;
    }
    > article {
      display: inline-block;
      transition: 0.3s ease-in-out;
      width: 650px;
      position: relative;
      direction: rtl;
      vertical-align: top;
      height: 100%;
      overflow: hidden;

      div.item {
        height: 100%;
        position: relative;
      }

      &.left-curtain,
      &.active,
      &.right-curtain {
        background-color: black;
      }

      @supports (-webkit-appearance: none) and (not (overflow: -webkit-marquee)) {
        // This whole block applies to Chrome only (not Safari), and fixes a Chrome rendering bug where on the GI homepage carousel, clicking the back/left button shows a black box. Issue seems to have appeared with Chrome ~ v52; so you can delete this block if that version is obsolete and issue is not present.
        &.left-curtain {
          img {
            outline: solid transparent 1px;
          }
        }
      }

      &.left-curtain,
      &.right-curtain {
        video {
          opacity: 0.6;
        }
      }

      a {
        cursor: default;
        max-width: 100%;
      }
      img {
        max-width: none;
        display: block;
        filter: opacity(0.6);
        transition: 0.6s ease-in-out;
      }

      &.active {
        img {
          filter: none;
        }
        video {
          opacity: 1;
        }
        figcaption {
          display: inline;
        }
        a {
          cursor: pointer;
        }
      }

      figcaption {
        display: none;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
        direction: ltr;
        color: white;
        font-family: $heading_font_family;
        font-size: 18px;
        line-height: 174%;
        font-weight: 200;
        text-align: left;
        span {
          background: transparentize(black, 0.3);
          padding: 0.4rem;
          position: relative;
          &.spacer {
            background-color: transparent;
            padding: 0 0.25rem;
          }
        }
      }

      .video-container {
        height: 100%;
        width: 100%;
        position: absolute;
        direction: ltr;
        left: 0;
        top: 0;

        .card-link {
          position: relative;
        }
      }
      .video {
        // full width, horizontal bars or cutting off height
        &.width-100 {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          max-width: 650px;
        }

        // full height, vertical bars or cutting off sides
        &.height-100 {
          height: 100%;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          max-height: 340px;
        }
      }
      .card-link {
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
        text-decoration: none;
        color: white;
      }
    }
  }

  @media (max-width: 650px) {
    .slide-control {
      top: 15%;
    }

    .hero-slides {
      margin-left: 0 !important;

      > article {
        max-width: 100%;
        display: none;
        &.active {
          display: inline-block;
        }

        img {
          max-width: 100%;
        }
      }
    }
  }
}
