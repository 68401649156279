.boards-slider {
  display: block;
  background-color: #262626;
  padding-top: 10px;
  color: #ccc;
  width: 100%;
  user-select: none;

  h3 {
    text-align: left;
    color: #ccc;
    font-size: 18px;
    margin: 0px 0 10px 50px;
    font-weight: light;
    a {
      color: white;
      text-decoration: none;
    }
  }

  label {
    color: #ccc;
  }

  ul {
    text-align: center;
    display: inline-block;
    min-height: 280px;
    li {
      max-width: 190px;
      position: relative;
      vertical-align: top;
      display: inline-block;
      .cover {
        transition: all 0.2s ease-in-out;
        display: block;
        width: 170px;
        height: 170px;
        opacity: 0.6;
        background-position: center center;
        background-size: cover;
        margin: 10px;
        z-index: 100;
        // box-shadow:
        //   0 1px 1px rgba(0,0,0,0.85), /* The top layer shadow */
        //   0 6px 0 -2px #777, /* The second layer */
        //   0 6px 1px -1px rgba(0,0,0,0.85), /* The second layer shadow */
        //   0 12px 0 -4px #777, /* The third layer */
        //   0 12px 1px -5px rgba(0,0,0,0.85); /* The third layer shadow */

        &:hover {
          opacity: 1;
          // transform: scale(1.1);
        }
      }
      .name {
        font-weight: lighter;
        margin-bottom: 18px;
        width: 100%;
        text-transform: uppercase;
        max-width: 220px;
        font-size: 18px;
        user-select: text;
      }
      .count {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        font-size: 50px;
        font-weight: lighter;
        color: white;
        margin-top: 45%;
        cursor: pointer;
        pointer-events: none;
      }
      .msg {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        background-color: #3dcd58;
        position: absolute;
        right: -2px;
        top: -5px;
        font-size: 14px;
        padding-top: 4px;
        cursor: pointer;
        display: block;
        color: white;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .nav {
    display: inline-block;
    opacity: 0.7;
    font-family: GettyIcons;
    font-size: 50px;
    vertical-align: top;
    margin-top: 90px;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .image_pager_left:before {
    content: '⟨';
  }
  .image_pager_right:after {
    content: '⟩';
  }
}
