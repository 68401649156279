.static-hero {
  height: 340px;
  padding-top: 7rem;
  padding-bottom: 12rem;
  background-size: cover;
  background-position: center center;

  > * {
    color: white;

    @extend %textshadow;

    text-align: center;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 465%;
    margin-bottom: 1rem;
  }

  p {
    font-size: 200%;
    font-weight: bold;
    line-height: 100%;
  }
}
