.collections_container {
  #search-container-fancy {
    // markup is hosted in autonomy via the Search_Box_GI_Collections_Wide.xsl skin
    display: flex;
    justify-content: center;
    padding-top: 1.4rem;

    .search_box {
      display: flex;
    }
  }

  .main_body {
    padding-top: 51px;
  }

  .promo-banner {
    background-color: #000;
    overflow: auto;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: white;
    }

    .image-container,
    .copy-container {
      width: 40%;
    }

    &:nth-of-type(even) {
      background-color: $bravo_gray;

      .image-container {
        float: left;
        margin-left: 10%;
        margin-right: 0;
        text-align: left;
      }

      .copy-container {
        float: right;
        margin-left: 0;
        margin-right: 10%;
      }
    }

    &:nth-of-type(odd) {
      background-color: #333;

      .image-container {
        float: right;
        margin-left: 0;
        margin-right: 10%;
        text-align: right;
      }

      .copy-container {
        float: left;
        margin-left: 10%;
        margin-right: 0;
      }
    }
  }

  .cms-bookend {
    text-align: center;
    background-color: #000;
    overflow: auto;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
      max-width: 40%;
      margin-left: auto;
      margin-right: auto;
      color: white;
    }

    a.button {
      background-color: $button_color;
      display: block;
      margin-top: 1rem;
      margin-bottom: 1rem;

      &.alt {
        border: solid white 1px;
        background-color: transparent;

        &:hover,
        &:focus {
          background-color: #666;
        }
      }
    }
  }
}
